import Steam from './Steam';

export default function Boller(props){
  return (
    <>
      <g id="boller">
        <path
          d="M314.85 233.12c1.91-57.32 116.17-62.09 113.5 0z"
          fill="url(#radial-gradient)"
        />
        <path
          className="cls-4"
          d="M430.11 235.12c0-1.75-122.78 3.56-117.24-2.32 3.52-60.59 121.05-65.11 117.24 2.32zm-112.77-4h109.11c-.81-55.3-103.45-54.7-109.11 0z"
        />
        <path
          d="M519.82 233.12c5-63 117-55.57 121.5 0z"
          fill="url(#radial-gradient-2)"
        />
        <path
          className="cls-4"
          d="M643.48 235.12c-.06-1.76-131.17 3.58-125.63-2.32 4.4-64.53 122.7-59.03 125.63 2.32zm-121.17-4H639c-9.91-52.44-108.59-57.23-116.69 0z"
        />
        <path
          d="M1058.47 234.12c-1.78-61.1-118.93-55.09-120.12 0z"
          fill="url(#radial-gradient-3)"
        />
        <path
          className="cls-4"
          d="M1060.81 236.12c-.31-1.5-128.73 3.09-124.46-2.1 6.72-61.09 120.91-63.54 124.46 2.1zm-120.19-4H1056c-7.55-56.64-106.53-52.26-115.38 0z"
        />
        <path
          d="M848.68 233.12c2.08-50.76-121.55-65.53-118.38 0z"
          fill="url(#radial-gradient-4)"
        />
        <path
          className="cls-4"
          d="M850.9 235.12c-.3-1.53-126.23 3.08-122.6-2-1.1-67.8 122.85-56.33 122.6 2zm-118.52-4H846.3c-6.69-51.86-112.53-56.96-113.92 0z"
        />
        <path
          fill={"#513523"}
          d="M345.48 200.67c1.48 2.63-.43 7-4.59 9.36s-7 1.76-8.48-.87-2.37-6 1.79-8.33 9.8-2.83 11.28-.16zM387.35 191c.62-3 5-6.24 9.63-5.25s6.41 3.3 5.79 6.25-.9 5.4-5.57 4.41-10.48-2.41-9.85-5.41zM589.62 192.46a3.5 3.5 0 012.64-4.41c2.51-.53 3.87.22 4.21 1.81s.74 2.86-1.78 3.39-4.69.8-5.07-.79zM742.74 213.23a3.5 3.5 0 012.64-4.41c2.52-.54 3.88.22 4.22 1.81s.73 2.85-1.78 3.39-4.74.8-5.08-.79zM959.35 210.32a3.5 3.5 0 012.65-4.41c2.51-.54 3.87.22 4.21 1.81s.74 2.85-1.78 3.39-4.74.8-5.08-.79zM812.28 198.58a3.49 3.49 0 012.64-4.41c2.51-.54 3.87.22 4.21 1.81s.74 2.85-1.78 3.39-4.73.8-5.07-.79zM992.53 214.12a3.5 3.5 0 012.64-4.41c2.52-.53 3.88.22 4.22 1.81s.73 2.85-1.78 3.39-4.74.8-5.08-.79zM351.17 195.36a3.49 3.49 0 012.64-4.41c2.52-.54 3.88.22 4.21 1.81s.74 2.85-1.77 3.38-4.74.86-5.08-.78zM571.38 215.37c-1.38-1.59-2.28-4.49.25-6.67s4.55-2.17 5.92-.57 2.63 2.78.1 5-4.89 3.87-6.27 2.24zM621.45 201c1.6-1.68 4.67-3 7.32-.44s4.86 5.74 3.25 7.42-4.77 2.16-7.42-.37-4.76-4.96-3.15-6.61zM542.58 200.26c-1.06-2.82.83-7.93 5.29-9.61s9.38-1.1 10.44 1.73 0 5.46-4.52 7.14-10.15 3.57-11.21.74zM757.4 195.44c-1.06-2.83.83-7.93 5.3-9.61s9.37-1.1 10.43 1.73 0 5.46-4.51 7.14-10.16 3.56-11.22.74zM787 211.08c-.18-3 3.13-7.33 7.9-7.61s9.27 1.72 9.46 4.73-1.66 5.21-6.43 5.49-10.78.41-10.93-2.61zM987.35 191.18c-.18-3 3.14-7.33 7.9-7.61s9.28 1.71 9.46 4.73-1.66 5.2-6.42 5.49-10.76.4-10.94-2.61zM830.94 199.37c2.24-2 7.68-2.16 10.89 1.38s4.48 8.3 2.24 10.33-5.09 2-8.3-1.56-7.06-8.12-4.83-10.15zM1015.59 203.33c.1-3 3.8-7 8.57-6.85s9.08 2.57 9 5.58-2.13 5-6.9 4.88-10.77-.59-10.67-3.61zM380.27 210c.37-2.18 3.47-3.8 6.91-3.22s4.79 2.2 4.43 4.38-1.39 4.5-4.84 3.92-6.86-2.95-6.5-5.08z"
        />
      </g>
      {<Steam amountOfSteamBalls={18} steamMinXPos={350} maxSteamBallRad={60} minSteamBallRad={40}/>}
      <path
        id="tray"
        className="cls-8"
        d="M290.91 220.34h783.41v33.96H290.91z"
      />
    </>
  );
}
