export const boller = [
  'fastelavnsbolle',
  'skolebolle',
  'tangzhongbolle',
  'glutenfri bolle',
  'kanelbolle',
  'solbolle',
  'havrebolle',
  'skillingsbolle',
  'korsbolle',
  'fransk bolle',
  'berlinerbolle',
  'sjokoladebolle',
  'rosinbolle',
  'espabolle',
  'jubileumsbolle',
  'karamellbolle',
  'grovis',
];
